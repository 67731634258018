/*----- index layout -----*/

#containar {
    margin-bottom: 50px;
  }
  
  .top--topics {
    padding: 0 0 40px;
    background-color: #faf4ed;
  }
  
  .neg-box {
    margin-top: -55px;
  }
  
  /* tabbox */
  
  #tabMenu {
    ul {
      width: 100%;
    }
  
    li {
      float: left;
      width: 20%;
  
      a {
        display: block;
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-right: #ffce96 1px solid;
        background-color: #ee9223;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
  
      &:last-child a {
        border-right: none;
      }
  
      a.active {
        background-color: #fff;
        color: #222;
      }
    }
  }
  
  #tabBox0, #tabBox1, #tabBox2, #tabBox3, #tabBox4 {
    width: 100%;
    padding: 35px 25px 20px;
    min-height: 300px;
    background-color: #fff;
  }
  
  #tabBox1, #tabBox2, #tabBox3, #tabBox4 {
    display: none;
  }
  
  .top--topics dl {
    display: table;
    width: 100%;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
    margin-bottom: 25px;
    font-weight: 200;
  
    dt, dd {
      padding: 0 10px;
    }
  
    dt {
      display: table-cell;
      width: 30%;
      font-weight: 200;
  
      span {
        display: inline-block;
        width: 140px;
        padding: 2px 0;
        margin-right: 20px;
        color: #fff;
        text-align: center;
      }
    }
  }
  
  span {
    &.news {
      background-color: #24a6e3;
    }
  
    &.consultation {
      background-color: #ee9223;
    }
  
    &.important {
      background-color: #fe1800;
    }
  
    &.seminar {
      background-color: #4a7b06;
    }
  }
  
  .top--topics dl {
    dt time {
      display: inline-block;
    }
  
    dd a {
      color: #444;
      text-decoration: underline;
    }
  }
  
  /* tabbox END */
  
  .topic_bnr {
    padding: 0 0 40px;
    background-color: #faf4ed;
  
    figure {
      max-width: 865px;
      width: 90%;
      margin: 0 auto 10px;
  
      img {
        width: 100%;
      }
    }
  }
  
  ul.notes_list {
    max-width: 865px;
    width: 90%;
    margin: 0 auto;
  
    li {
      padding-left: 18px;
      position: relative;
      text-align: left;
  
      &::before {
        content: "※";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  
  .top--contents {
    padding: 0;
  
    figure {
      width: 100%;
      max-height: 300px;
      overflow: hidden;
      position: relative;
      z-index: 0;
  
      img {
        width: 100%;
      }
  
      figcaption {
        position: absolute;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px 0;
        text-align: center;
  
        h3 {
          font-size: 24px;
          color: #fff;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }
  
  @media (max-width: 1320px) {
    .top--contents figure {
      max-height: none;
    }
  }
  
  .top--case {
    padding: 50px 0;
  
    div.row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
  
    .col-sm-3 {
      padding: 0 20px 10px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
  
      &:last-child {
        border-right: 1px solid #ddd;
      }
  
      p.num {
        color: #1d97d0;
        font-weight: 600;
        margin: 0 0 15px;
  
        span {
          display: inline-block;
          padding: 3px 10px;
          background-color: #ee9223;
          color: #fff;
        }
      }
  
      h3 {
        font-size: 16px;
        font-weight: 600;
      }
  
      div.txt--area {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin: 1em 0;
        padding: 5px 0;
        font-size: 13px;
      }
  
      dl {
        margin-bottom: 5px;
  
        dt, dd {
          display: table-cell;
        }
      }
    }
  }
  
  .top--consultation {
    margin: 4em 0;
    padding: 4em 0;
    background-color: #faf4ed;
  
    p.mincho {
      font-size: 22px;
    }
  
    .box {
      margin: 5px;
      padding: 1px;
      background-color: #fff;
      overflow: hidden;
    }
  
    figure {
      float: left;
      width: 40%;
      height: 110px;
      overflow: hidden;
      position: relative;
  
      img {
        width: 100%;
        position: absolute;
      }
    }
  
    h3 {
      float: right;
      width: 60%;
      padding: 0 2px 0 8px;
      font-size: 15px;
      line-height: 1.3;
      font-weight: 400;
    }
  
    p.last-txt {
      font-size: 18px;
    }
  }
  
  .top--advantage {
    padding: 4em 0;
  
    .row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
  
    .col-md-4 {
      .box {
        border: 1px solid #ddd;
        margin: 15px;
        min-height: 370px;
        position: relative;
  
        .bg {
          background-color: #f4f4f4;
          text-align: center;
          padding: 20px 0 15px;
        }
      }
  
      h3 {
        font-weight: 500;
        margin: 15px 0 0;
        font-size: 18px;
  
        &.sm {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
  
    p {
      margin: 20px;
    }
  
    .pos {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
    }
  
    a.button02 {
      padding: 8px 0 5px;
      font-size: 14px;
      max-width: 60%;
    }
  }
  
  .top--contact {
    padding: 4em 0;
    background-color: #faf4ed;
  
    .col-sm-4 {
      .box {
        margin: 10px;
        padding: 15px 20px 20px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        background-color: #fff;
      }
  
      &.center-col {
        margin: 0 4%;
      }
  
      h3 {
        margin: 0;
  
        span {
          display: inline-block;
          padding: 5px 30px;
          text-align: center;
          background-color: #ee9223;
          color: #fff;
          font-size: 16px;
        }
      }
  
      p {
        font-weight: 600;
        margin: 10px 0;
  
        &.tel-txt {
          font-size: 36px;
          border-bottom: 1px solid #ddd;
  
          em {
            font-style: normal;
            font-size: 0.75em;
          }
        }
      }
    }
  }
  
  .top--flow {
    padding: 4em 0;
  }
  
  .message {
    padding: 6em 0;
  
    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.8;
      color: #111;
    }
  }
  
  .foot--bnr {
    max-width: 1180px;
    margin: auto;
  
    .col-sm-6 {
      padding: 7px;
    }
  }
  
  .foot_area {
    max-width: 1180px;
    width: 96%;
    margin: 2em auto 1.5em;
  }
  
  .foot-area {
    max-width: 1180px;
    width: 96%;
    margin: 4em auto 1em;
  
    .img-wrap {
      max-width: 490px;
    }
  
    .inner_right {
      flex: 1;
      margin-left: 5%;
      font-size: 28px;
      font-weight: 600;
    }
  }
  
  /*----- common style -----*/
  
  .neg__block {
    max-width: 960px;
    margin: -120px auto 0;
    padding: 65px 0 0;
    background-color: #fff;
  
    .inner__block {
      max-width: 810px;
    }
  }
  
  /* ttl */
  
  .h2__ttl--center {
    font-size: 38px;
    margin: 0 0 70px;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color: #111;
    position: relative;
  
    &:after {
      bottom: -40%;
      content: '';
      height: 4px;
      width: 120px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #dcdcdc;
    }
  }
  
  .h3__ttl--left {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
  
    span.txt-orenge {
      font-size: 1.2em;
      margin-right: 10px;
      font-weight: 300;
    }
  }
  
  .h4__ttl {
    font-size: 18px;
    color: #ee9223;
    margin-bottom: 15px;
  }
  
  .bar__ttl {
    font-size: 1.6rem;
    background-color: #808080;
    color: #fff;
    padding: 10px 0;
    text-align: center;
  }
  
  .sm--ttl {
    font-weight: 600;
    margin-bottom: 25px;
    padding-bottom: 5px;
    background: url(../images/common/line02.png) no-repeat left bottom;
  }
  
  /* font */
  
  .txt-yellow {
    color: #f8dc1f;
  }
  
  .txt-blue {
    color: #1d97d0;
  }
  
  .txt-orenge {
    color: #f17d00;
  }
  
  .txt-red {
    color: #f00;
  }
  
  .txt-pink {
    color: #E73E6D;
  }
  
  .b--txt {
    font-weight: 600;
  }
  
  .marker {
    background: linear-gradient(transparent 60%, #edf268 60%);
  }
  
  .fsize16 {
    font-size: 1.6rem;
  }
  
  .fsize18 {
    font-size: 1.8rem;
  }
  
  .fsize20 {
    font-size: 2rem;
    font-weight: 300;
  }
  
  /*　btn　*/
  
  .arw--left {
    background: url(../images/common/arw_01.png) no-repeat left center;
    padding-left: 35px;
    display: inline-block;
  }
  
  .arw--right {
    background: url(../images/common/arw_01.png) no-repeat right center;
    padding-right: 35px;
    display: inline-block;
  }
  
  .submit_btn {
    -webkit-appearance: none;
    background: url(../images/contact/button_bg.png) no-repeat left top;
    background-size: cover;
    border: none;
    line-height: 1;
    padding: 18px 110px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }
  
  .r_btn {
    a {
      display: block;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0;
      text-align: center;
      border: 1px solid;
      border-radius: 30px;
      font-size: 20px;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s;
    }
  
    &.txt-orenge a {
      border-color: #ee9223;
      color: #ee9223;
  
      &:hover {
        border-color: #ffb050;
        color: #ffb050;
      }
    }
  }
  
  a {
    &.button {
      display: block;
      max-width: 400px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      border: 2px solid #ee9223;
      background: #fff;
      padding: 18px 0 15px;
      text-align: center;
      color: #ee9223;
      font-size: 16px;
      font-weight: 600;
      position: relative;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s;
  
      &:after {
        content: "";
        background: url(../images/common/arw.png) no-repeat 80% center;
        position: absolute;
        top: 38%;
        right: 10%;
        width: 12px;
        height: 16px;
        background-size: 12px 16px;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &:hover {
        background-color: #ee9223;
        color: #fff;
  
        &:after {
          right: 7%;
          filter: alpha(opacity = 0);
          opacity: 0;
        }
      }
    }
  
    &.button02 {
      display: block;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #ee9223;
      padding: 15px 0 12px;
      text-align: center;
      color: #ee9223;
      font-size: 16px;
      position: relative;
      box-sizing: border-box;
  
      &:after {
        content: "";
        background: url(../images/common/arw.svg) no-repeat 80% center;
        position: absolute;
        top: 38%;
        right: 10%;
        width: 12px;
        height: 12px;
        background-size: 12px 12px;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &:hover:after {
        right: 7%;
        filter: alpha(opacity = 0);
        opacity: 0;
      }
    }
  }
  
  /* text link */
  
  .text-link {
    display: inline-block;
    color: #ee9223;
    padding: 2px 25px 2px 0;
    background: url(../images/common/arw02.png) no-repeat right center;
    background-size: 14px 14px;
  }
  
  /* list */
  
  .link-group a {
    position: relative;
  
    span {
      margin-left: 20px;
      -webkit-transition: all .3s;
      transition: all .3s;
    }
  
    &:hover span {
      margin-left: 30px;
    }
  }
  
  .disc--list li {
    list-style: disc outside;
    margin: 0 0 10px 20px;
    font-size: 0.85em;
  }
  
  .decimal--list li {
    list-style: decimal inside;
    display: list-item;
  }
  
  .katakana--list li {
    list-style: katakana inside;
    display: list-item;
    color: #41bc2d;
  }
  
  .qa--list {
    dt {
      font-size: 2.6rem;
      font-weight: 400;
      border-bottom: 1px solid #ddd;
      padding: 5px 0 10px 40px;
      position: relative;
  
      &:before {
        content: "Q.";
        font-size: 2.8rem;
        display: inline-block;
        color: #ee9223;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  
    dd {
      margin-bottom: 5em;
      font-size: 1.1em;
      padding: 20px 10px 0 40px;
      line-height: 1.6;
      position: relative;
  
      &:before {
        content: "A.";
        font-size: 2.8rem;
        display: inline-block;
        color: #ddd;
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
  }
  
  /* balloon-1 right */
  
  .balloon-1-right {
    position: relative;
    display: inline-block;
    padding: 20px;
    width: auto;
    min-width: 115px;
    height: auto;
    background: #fff;
    border-radius: 10px;
    z-index: 0;
  
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -14px;
      margin-top: -15px;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent #fff;
      z-index: 0;
    }
  }
  
  /* bg */
  
  .gray_bg {
    background-color: #e8e8e8;
    padding: 4% 4% 2%;
    margin-bottom: 1.5em;
  
    ul li {
      background: #fff url( ../images/common/check.png) no-repeat 15px center;
      font-size: 1.6rem;
      font-weight: 600;
      padding: 12px 15px 12px 50px;
      margin-bottom: 15px;
      box-shadow: 5px 5px 0px -2px #d6d6d6;
      -moz-box-shadow: 5px 5px 0px -2px #d6d6d6;
      -webkit-box-shadow: 5px 5px 0px -2px #d6d6d6;
    }
  }
  
  /*----- sub layout -----*/
  
  #l-sub-contener {
    margin-bottom: 8em;
    font-size: 1.5rem;
  }
  
  /* advantage */
  
  .advantage {
    .col-sm-4 a {
      display: block;
      margin: 15px 15px 0;
      border: 2px solid #ee9223;
      padding: 15px;
      text-align: center;
      color: #e58006;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s;
  
      &:hover {
        background-color: #ee9223;
        color: #fff;
      }
    }
  
    .mtbox {
      margin-top: -3em;
      padding-top: 5.5em;
    }
  
    .btn-info {
      color: #fff;
    }
  }
  
  #adva04.mtbox {
    box-sizing: border-box;
    border: 10px solid #eee;
    padding: 1% 4% 2%;
    margin-top: 5.5em;
  }
  
  /* merit */
  
  .merit .col-sm-6 {
    padding: 20px;
  }
  
  /* type */
  
  .type {
    .col-sm-4 .box {
      border: 1px solid #ddd;
      padding: 15px;
      margin: 0 10px;
  
      .num {
        margin-right: 6px;
      }
    }
  
    .panel-heading p {
      font-size: 1.25em;
      margin: 10px 0;
    }
  
    .panel-body p {
      margin: 10px 0 0;
      font-size: 1.1em;
    }
  }
  
  /* trial */
  
  .trial {
    h3 {
      margin-top: 2em;
    }
  
    h4.h4__ttl {
      margin: 25px 0 0;
    }
  
    .list-inline li {
      display: inline-block;
      width: 32.333%;
      margin: 0 1% 0 0;
      padding: 15px 25px;
      vertical-align: top;
  
      &:nth-child(3n+3) {
        margin-right: 0;
      }
  
      &:nth-child(4), &:nth-child(5) {
        width: 49%;
        margin: 1% 0 0;
      }
  
      &:nth-child(4) {
        margin-right: 1%;
      }
  
      p {
        margin: 0;
        font-size: 1.6rem;
  
        .num {
          color: #f00;
          margin-right: 6px;
        }
      }
    }
  
    .katakana--list .list-group-item {
      position: relative;
      color: #f00;
  
      p {
        font-size: 0.95em;
        padding-left: 25px;
        color: #444;
      }
    }
  }
  
  /* register */
  
  .register {
    figure {
      float: right;
      width: 15%;
      margin-top: 25px;
  
      img {
        width: 100%;
      }
    }
  
    .txt--area {
      float: left;
      width: 80%;
    }
  
    .list-group-item {
      position: relative;
  
      &:nth-child(even) {
        background-color: #fafafa;
      }
  
      p {
        font-size: 1.05em;
        padding-left: 40px;
      }
    }
  
    .num {
      font-size: 1.5em;
      color: #ee9223;
      display: block;
      position: absolute;
      top: 20px;
      left: 15px;
    }
  }
  
  /* children */
  
  .children .decimal--list li p {
    margin: 5px 15px;
    font-size: 0.9em;
    color: #444;
  }
  
  /* custody */
  
  .custody .panel-title {
    font-size: 1.8rem;
  }
  
  /* money */
  
  .money .katakana--list li p {
    margin: 5px 25px;
    font-size: 0.9em;
    color: #444;
  }
  
  /* married */
  
  .married h3 {
    margin-top: 2em;
  }
  
  /* pension */
  
  .pension table {
    th {
      text-align: center;
    }
  
    td {
      width: 42.5%;
  
      &:first-child {
        width: 15%;
      }
    }
  }
  
  /* child */
  
  .child {
    h3 {
      margin-top: 2em;
    }
  
    h4.h4__ttl {
      margin: 25px 0 0;
    }
  }
  
  /* property */
  
  .property {
    h3 {
      margin-top: 2em;
    }
  
    h4.h4__ttl {
      margin: 25px 0 0;
    }
  
    .list-group-item p .num {
      font-size: 1.1em;
      color: #ee9223;
      margin-right: 6px;
    }
  }
  
  /* flow */
  
  .flow {
    .flow--list li {
      margin-bottom: 6em;
  
      .box {
        border-left: 6px solid #ee9223;
        padding: 5px 20px;
  
        p {
          margin: 0;
  
          &.mincho {
            font-size: 3rem;
  
            em {
              font-size: 0.8em;
              font-style: normal;
            }
          }
        }
      }
    }
  
    a.btn {
      color: #fff;
  
      &:link {
        color: #fff;
      }
    }
  }
  
  /* faq */
  
  .faq .qa--list {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 2.5em;
  
    dt {
      font-size: 2.4rem;
    }
  
    dd {
      margin-bottom: 0;
    }
  }
  
  /* voice */
  
  .voice .voice--list {
    overflow: hidden;
  
    li {
      float: left;
      width: 29.333%;
      margin-top: 2%;
      border: 1px solid #ddd;
  
      &:nth-child(3n+2) {
        margin: 2% 2% 0;
      }
  
      figure {
        position: relative;
        width: 100%;
        max-height: 340px;
        overflow: hidden;
  
        img {
          width: 100%;
        }
      }
    }
  }
  
  /* cost */
  
  .cost {
    h3 {
      margin-top: 2.5em;
    }
  
    table {
      tr {
        th, td {
          vertical-align: middle;
          text-align: center;
          padding: 2% 1%;
        }
  
        th {
          width: 50%;
        }
  
        td:first-child {
          width: 18%;
        }
      }
  
      .bg01 {
        background-color: #f8f8f8;
        color: #111;
      }
  
      .bg02 {
        background-color: #ee9223;
        color: #fff;
      }
    }
  }
  
  /* contact */
  /* contact */
  
  .contact label, .confirm label {
    font-size: 1.1em;
    margin-bottom: 8px;
  }
  
  .contact {
    .form-group {
      margin-bottom: 1.5em;
      width: 100%;
    }
  
    .btn {
      padding: 8px 40px;
      margin: 40px 10px 10px;
    }
  }
  
  .confirm .btn {
    padding: 8px 40px;
    margin: 40px 10px 10px;
  }
  
  .contact .req {
    margin-left: 10px;
    font-size: 0.8em;
    font-weight: 400;
    color: firebrick;
  }
  
  .confirm {
    .form-group {
      width: 100%;
      display: table;
      margin-bottom: 0;
  
      &.end {
        border-bottom: 1px solid #ccc;
      }
    }
  
    .form-ttl {
      width: 26%;
      padding: 2%;
      vertical-align: middle;
      display: table-cell;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  
    .form-txt {
      width: 76%;
      padding: 2%;
      display: table-cell;
      border-top: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  
    .req {
      display: none;
    }
  }
  
  .wm {
    width: 40% !important;
    display: inline-block;
  }
  
  .ws {
    width: 25% !important;
    display: inline-block;
  }
  
  /* construction */
  
  .construction {
    margin: 10em 0 20em;
  
    p {
      text-align: center;
      font-size: 2rem;
  
      &.b--txt {
        font-size: 5rem;
        font-weight: 200;
        color: #ccc;
      }
    }
  }
  
  /* topics
  -------------------------*/
  
  .topic dl {
    display: table;
    width: 100%;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
    margin-bottom: 25px;
  
    dt {
      padding: 0 10px;
    }
  }
  
  .top--topics dl dd {
    padding: 0 10px;
  }
  
  .topic dl {
    dt {
      display: table-cell;
      width: 30%;
      font-weight: 500;
  
      span {
        display: inline-block;
        width: 140px;
        padding: 4px 0;
        margin-right: 20px;
        color: #fff;
        text-align: center;
      }
  
      time {
        display: inline-block;
      }
    }
  
    dd a {
      color: #444;
      text-decoration: underline;
    }
  }
  
  .rea_btn a {
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 15px 35px;
    font-size: 1.8rem;
    border: 2px solid #24a6e3;
    transition: 0.5s;
  }
  
  .topic .rea_btn {
    margin-bottom: 3em;
  
    .col-sm-3 {
      padding: 5px 10px;
    }
  
    a {
      background: #fff;
      text-align: center;
    }
  }
  
  .rea_btn a {
    &.news {
      color: #24a6e3;
      border-color: #24a6e3;
  
      &:hover {
        background-color: #24a6e3;
      }
    }
  
    &.consultation {
      color: #ee9223;
      border-color: #ee9223;
  
      &:hover {
        background-color: #ee9223;
      }
    }
  
    &.important {
      color: #fe1800;
      border-color: #fe1800;
  
      &:hover {
        background-color: #fe1800;
      }
    }
  
    &.seminar {
      color: #4a7b06;
      border-color: #4a7b06;
  
      &:hover {
        background-color: #4a7b06;
      }
    }
  
    &:hover {
      color: #fff;
    }
  }
  
  /* topic_detail */
  
  .topic_detail {
    .t_item {
      background: #888888;
      color: #fff;
      padding: 2px 20px;
      margin-right: 10px;
    }
  
    h2 {
      font-size: 2.9rem;
      padding: 20px 0;
      border-bottom: 1px dashed #dddddd;
    }
  }
  
  .editor-block {
    margin-bottom: 6em;
  }
  
  /* sitemap */
  
  .sitemap a {
    color: #333;
  }
  
  .catetag {
    border-bottom: 1px solid #666;
    padding: 5px;
    font-size: 1.25em;
    margin: 2.5em auto 0;
  
    a:hover {
      color: #ee9223;
    }
  }
  
  ul {
    &.sitemap--list {
      padding-left: 1%;
  
      li {
        margin: 1.5% 4% 0 0;
        line-height: 1.4;
        width: 29%;
        display: inline-block;
        vertical-align: top;
  
        &:nth-child(3n+3) {
          margin-right: 0;
        }
  
        a {
          display: block;
          padding: 6% 0;
          border-bottom: 1px dashed #ddd;
          box-sizing: border-box;
          -webkit-transition: all .3s;
          transition: all .3s;
  
          &:before {
            content: "・";
          }
  
          &:hover {
            color: #ee9223;
          }
        }
      }
    }
  
    &.privacy--list {
      padding: 0 25px;
  
      li {
        list-style: decimal outside;
        padding: 30px 0;
        margin-top: 2em;
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        font-weight: 400;
        font-size: 1.05em;
      }
    }
  }
  
  /* privacy */
  
  /* area */
  
  .area {
    .inner__block {
      padding-bottom: 8em;
    }
  
    .mtbox {
      margin-top: 5.5em;
    }
  
    a {
      &.link_txt {
        font-size: 1.4rem !important;
        padding-top: 5px;
      }
  
      &.telhref {
        color: #ee9223;
  
        &:link {
          color: #ee9223;
        }
      }
    }
  
    .greet_box {
      margin-top: 2.5em;
      box-sizing: border-box;
      border: 10px solid #eee;
      padding: 1% 4% 2%;
    }
  }

/* line_yoyaku */
.ol-list-01{
  counter-reset:ol_li;
  li{
    position:relative;
    padding-left:35px;

    &::before{
      counter-increment:ol_li;
      content:counter(ol_li,decimal-leading-zero) ".";
      color:#ee9223;
      font-weight: 600;
      position:absolute;
      top:0;
      left:0;
    }
    &:not(:last-child){
      margin-bottom:5px;
    }
  }
}

.ul-list-01{
  li{
    position:relative;
    padding-left:20px;
    
    &::before{
      content:'〇';
      position:absolute;
      top:0;
      left:0;
    }
    &:not(:last-child){
      margin-bottom:10px;
    }
  }
}

.line__wrap{
  line-height: 2;
  &-flow{
    background-color: #FAFAFA;
    padding: 40px;
    line-height: 2;
    border: 3px solid #01B901;
    &--ttl{
      margin: 0 0 40px;
      padding: 6px;
      font-size: 22px;
      background: #01B901;
      color: #fff;
      text-align: center;
    }
    &--link{
      li{
        &:not(:last-child){
          margin-right: 5%;
        }
      }
    }
    &--attention{
      background-color: rgba(255,255,255,0.8);
      padding: 40px 30px 30px;
      border: 1px solid #01B901;
      line-height: 1.6;
      position: relative;
      &---ttl{
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #fff;
        background-color: #FF0000;
        padding: 4px 15px;
        position: absolute;
        top: -15px;
        left: 30px;
      }
    }
    &--cost{
      background-color: rgba(255,255,255,0.8);
      padding: 40px 30px 30px;
      border: 1px solid #01B901;
      line-height: 1.6;
      position: relative;
      &---ttl{
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #fff;
        background-color: #FF0000;
        padding: 4px 15px;
        position: absolute;
        top: -15px;
        left: 30px;
      }
      &---contents{
        .items{
          &:not(:last-child){
            margin-bottom: 30px;
          }
          dt{
            width: 180px;
            span{
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
              color: #333;
              border: 1px solid #333;
              padding: 4px 15px;
              border-radius: 5px;
            }
          }
          dd{
            width: calc(100% - 180px);
            p{
              margin-top: 0;
            }
            .title{
              font-size: 16px;
              font-weight: 500;
              margin: 0 0 10px;
            }
            .list{
              margin-left: 24px;
              li{
                position: relative;
                padding-left: 20px;
                &:not(:last-child){
                  margin-bottom: 10px;
                }
                &::before{
                  content: '⇒';
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }

          //other
          &.kojin{
            dt{
              span{
                color: #4B7C07;
                border-color: #4B7C07;
              }
            }
            dd{
              .title{
                color: #4B7C07;
              }
            }
          }
          &.houjin{
            dt{
              span{
                color: #FF7E00;
                border-color: #FF7E00;
              }
            }
            dd{
              .title{
                color: #FF7E00;
              }
            }
          }
        }
      }
    }
  }
}
